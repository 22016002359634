import TabelaDane from "../TabelaDane/TabelaDane";

function ZakonczRozrachSzczegoly(Okno)
{
Okno("");

}

export default async function RozrachSzczegoly(cId,baza,WartoscWybranaW,WartoscWybranaL,WartoscWybranaWl,DaneRozrachSzczegoly,Okno)
{
    let adres_serwera="",EkranRozliczen="";
    let tablica_dane=[],Naglowek=[5],i=0,WynikZapytania=null,SzerokoscNaglowka=[5],WyrownanieKolumn=[5],WysokoscNaglowka,WysokoscPozostalychWierszy;
   
  let zapytanie={
    klucz_sesji: sessionStorage.getItem("xpert"),
    rekord_od:0,
    rekord_do:0,
    id_wspolnoty: WartoscWybranaW.value,
    id_konta_ksiegowe: DaneRozrachSzczegoly[cId].id_konta_ksiegowe,
    id_nag_rozrach: DaneRozrachSzczegoly[cId].id_nag_rozrach,
    nazwa_konta:DaneRozrachSzczegoly[cId].nazwa_konta,
    id_wlasciciela: WartoscWybranaWl.value,
    baza: baza
  };
    
  
  
  
  Naglowek[0]="Treść 1";
  Naglowek[1]="Treść 2";
  Naglowek[2]="Data";
  Naglowek[3]="Czynsz";
  Naglowek[4]="Wpłaty"
  SzerokoscNaglowka[0]="25%"
  SzerokoscNaglowka[1]="25%"
  SzerokoscNaglowka[2]="20%"
  SzerokoscNaglowka[3]="15%"
  SzerokoscNaglowka[4]="15%"
  WyrownanieKolumn[0]=1   //Lewo
  WyrownanieKolumn[1]=1   //Lewo
  WyrownanieKolumn[2]=1   //Lewo
  WyrownanieKolumn[3]=3   //Prawo
  WyrownanieKolumn[4]=3   //Prawo
  adres_serwera= window.sessionStorage.getItem("rodzaj_polaczenia");
  adres_serwera=adres_serwera+window.sessionStorage.getItem("adres");
  adres_serwera=adres_serwera+window.sessionStorage.getItem("port");
     
  

  const odpowiedz= await fetch(adres_serwera+'/rozliczenie_czynszu_szczegoly', {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },body: JSON.stringify(zapytanie)
  });
           
    WynikZapytania=await odpowiedz.json();

    
    
    const dodaj=async function(i)
    {
      tablica_dane.push(1);
      tablica_dane[i]=[];// Jest przykład w nauka_www

      tablica_dane[i].push(WynikZapytania[i].tresc1);
      tablica_dane[i].push(WynikZapytania[i].tresc2);
      tablica_dane[i].push(WynikZapytania[i].data);
      tablica_dane[i].push(WynikZapytania[i].czynsz);
      tablica_dane[i].push(WynikZapytania[i].wplata);

    }
    const DodajPodsumowanie=async function(i,DaneRozrachSzczegoly)
    {
      let LiczbaWn=0,LiczbaMa=0;
      tablica_dane.push(1);
      tablica_dane[i]=[];// Jest przykład w nauka_www
      tablica_dane[i].push("Razem ");
      tablica_dane[i].push("");
      tablica_dane[i].push("");
      tablica_dane[i].push(DaneRozrachSzczegoly[cId].suma_wn);
      tablica_dane[i].push(DaneRozrachSzczegoly[cId].suma_ma);
      i++;
      tablica_dane.push(1);
      tablica_dane[i]=[];// Jest przykład w nauka_www
      LiczbaMa= parseFloat(DaneRozrachSzczegoly[cId].suma_ma);
      LiczbaWn= parseFloat(DaneRozrachSzczegoly[cId].suma_wn);
      if(LiczbaMa < LiczbaWn)
      {
        console.log("jest mniej");
        tablica_dane[i].push("Niedopłata ");
        tablica_dane[i].push("");
        tablica_dane[i].push("");
        
        tablica_dane[i].push(DaneRozrachSzczegoly[cId].saldo);
        tablica_dane[i].push(0);
      }
      if(LiczbaMa > LiczbaWn)
      {
        console.log("jest więcej");
        tablica_dane[i].push("Nadpłata ");
        tablica_dane[i].push("");
        tablica_dane[i].push("");
        tablica_dane[i].push(0);
        tablica_dane[i].push(DaneRozrachSzczegoly[cId].saldo);
        
      }
      
    }
    i=0;
    
    while(i<WynikZapytania.length)
    {
      await dodaj(i);
      i++;
    }

    await DodajPodsumowanie(i,DaneRozrachSzczegoly);
   
    EkranRozliczen=<div>
      <TabelaDane Naglowek={Naglowek} Dane={tablica_dane} Liczba={i} SzerokoscNaglowka={SzerokoscNaglowka} WysokoscNaglowka={WysokoscNaglowka} WysokoscPozostalychWierszy={WysokoscPozostalychWierszy} Wyrownanie={WyrownanieKolumn} />;
      
      <button onClick={function(){ZakonczRozrachSzczegoly(Okno)}}>Zamknij</button>
    </div>
    

    
    return(Okno(<div>{EkranRozliczen}</div>));
}