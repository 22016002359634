import React, {useEffect, useState} from "react";
import  Logowanie from "../../komponenty/logowanie/Logowanie";
//import Pulpit from "../Pulpit/Pulpit";

import { useNavigate } from "react-router-dom";
import Przycisk from "../../komponenty/KlawiszButton/KlawiszButton";

//import { AdresSerwera} from "../../komponenty/ustawienia";
import './start.css';



function  Start(){
 
  const [Zalogowany,ZmienZalogowany]=useState(null);
  const navigate=useNavigate();
  const [PulpitWyswietl,ZmienPulpitWyswietl]=useState(null);
  const [DaneForm,ZmienDaneForm]=useState({nazwa: "", haslo: "",klucz_sesji:""});
  const [Flaga,UstFlaga]=useState(false);
  
  
  useEffect(function(){
    let adres_serwera=""
    let TrybPracy=1;   
    switch(TrybPracy)
    {
      case 0:
        window.sessionStorage.setItem("adres", "10.10.10.120:");
        window.sessionStorage.setItem("port","80");
        window.sessionStorage.setItem("rodzaj_polaczenia","http://");
        break;
        //window.sessionStorage.setItem("adres", "10.10.10.109:");
      case 1:
        window.sessionStorage.setItem("adres", "www.elokal.pl:");
        window.sessionStorage.setItem("port","443");
        window.sessionStorage.setItem("rodzaj_polaczenia","https://");
        //window.sessionStorage.setItem("adres", "10.10.10.109:");
        break;
      case 2:
        window.sessionStorage.setItem("adres", "10.10.10.110:");
        window.sessionStorage.setItem("port","13000");
        window.sessionStorage.setItem("rodzaj_polaczenia","http://");
        break;
      case 3:
        window.sessionStorage.setItem("adres", "127.0.0.1:");
        window.sessionStorage.setItem("port","80");
        window.sessionStorage.setItem("rodzaj_polaczenia","http://");
        break;
      case 4:
        window.sessionStorage.setItem("adres", "elokal.luxbit.com.pl:");
        window.sessionStorage.setItem("port","443");
        window.sessionStorage.setItem("rodzaj_polaczenia","https://");
        break;
      default:
        break;
    }
    
    
    
    if (DaneForm.haslo!=="")
    {
      adres_serwera= window.sessionStorage.getItem("rodzaj_polaczenia");
      adres_serwera=adres_serwera+window.sessionStorage.getItem("adres");
      adres_serwera=adres_serwera+window.sessionStorage.getItem("port");
      
      console.log("adres serwera "+adres_serwera);
      fetch(adres_serwera+'/logowanie', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(DaneForm)
      })
        .then(data =>data.json())
        .then(data=>{
          
          if(sessionStorage.getItem("xpert")===data.klucz_sesji)
          {
            window.sessionStorage.setItem("id_uzytkownika",data.id_uzytkownika);
            UstFlaga(true);
          }
          
        })
      
        
    }
    
    if(Flaga)
    {
      
      ZmienPulpitWyswietl(navigate("/pulpit"));
     
    }
  },[Flaga,navigate,DaneForm]);
  
  function ZalogujDoSerwera(){
    
    Logowanie(ZmienZalogowany,ZmienDaneForm)

            
 
  }
  return(
      
    <div className="StartTabela">
      
        <div className="baner">Luxbit - System obsługi mieszkańca</div>
        <div className="logowanie">
            {Przycisk("Logowanie",ZalogujDoSerwera)}
        </div>
        <div className="Informacje">Firma
        Firma Luxbit istnieje na rynku od 1989r. <br/>
        Główną naszą działalnością jest obsługa informatyczna firm. <br/>
        Od roku 2001 świadczymy również usługi  księgowe dla  Wspólnot Mieszkaniowych. <br/>
        Na potrzeby naszej firmy stworzyliśmy program księgowy oraz portal obsługi mieszkańca.<br/> 
        W ten sposób możemy świadczyć kompleksową obsługę Wspólnot Mieszkaniowych, dla których 
        dodatkowo możemy również zaproponować usługi związane z instalacją  internetu, 
        kontrolą dostępu, monitoringiem wizyjnym. <br/>
        W najbliższym czasie wachlarz naszych usług poszerzymy o zdalny odczyt liczników

 
        </div>
      
      

        {Zalogowany}
        {PulpitWyswietl}
        
    </div>
      
  );
  
}

export default Start;
