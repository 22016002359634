


async function ListaZarzadcow(){
  let zapytanie={
    klucz_sesji: sessionStorage.getItem("xpert"),id_uzytkownika: sessionStorage.getItem("id_uzytkownika")};
  let adres_serwera=""
    adres_serwera= window.sessionStorage.getItem("rodzaj_polaczenia");
    adres_serwera=adres_serwera+window.sessionStorage.getItem("adres");
    adres_serwera=adres_serwera+window.sessionStorage.getItem("port");

  const odpowiedz= await fetch(adres_serwera+'/lista_zarzadcow', {
    method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },body: JSON.stringify(zapytanie)
     
    });
    
  const ppp=await odpowiedz.json();
  
  return(ppp);
}
 

    
    


export default ListaZarzadcow;