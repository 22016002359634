


import TabelaDane from '../TabelaDane/TabelaDane';
import RozrachSzczegoly from './RozrachSzczegoly';




function ZakonczRozrach(Okno)
{
Okno("");

}

export async function RozrachunkiLista(baza,WartoscWybranaW,WartoscWybranaL,WartoscWybranaWl,DaneRozrachSzczegoly,UstDaneRozrachSzczegoly,Okno)
{

  let adres_serwera="",EkranRozliczen="",SzerokoscNaglowka=[1],WysokoscNaglowka,WysokoscPozostalychWierszy=10,Wyrownanie=[5];
  let zapytanie={
    klucz_sesji: sessionStorage.getItem("xpert"),
    rekord_od:0,
    rekord_do:0,
    id_wspolnoty: WartoscWybranaW.value,
    id_lokalu: WartoscWybranaL.value,
    id_wlasciciela: WartoscWybranaWl.value,
    baza: baza};

  let tablica_dane=[],Naglowek=[1],i=0,ObiektJson=[],roboczy={};
  
  Wyrownanie[0]=1;
  Wyrownanie[1]=1;
  Wyrownanie[2]=2;
  Wyrownanie[3]=3;
  Wyrownanie[4]=3;

  Naglowek[0]="Nazwa rozrachunku";
  adres_serwera= window.sessionStorage.getItem("rodzaj_polaczenia");
  adres_serwera=adres_serwera+window.sessionStorage.getItem("adres");
  adres_serwera=adres_serwera+window.sessionStorage.getItem("port");
     
  SzerokoscNaglowka[0] = "100%";
  
  const odpowiedz= await fetch(adres_serwera+'/rozliczenie_czynszu', {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },body: JSON.stringify(zapytanie)
  });
           
    const ppp=await odpowiedz.json();
   
    
    
    const dodaj=async function(i)
    {
      tablica_dane.push(1);
      tablica_dane[i]=[];// Jest przykład w nauka_www*/
      roboczy={id_wlasciciela: WartoscWybranaWl.value,id_konta_ksiegowe: ppp[i].id_konta_ksiegowe,id_nag_rozrach: 0,nazwa_konta: ppp[i].nazwa_konta,suma_wn: ppp[i].suma_wn, suma_ma:ppp[i].suma_ma, saldo:ppp[i].saldo};
      ObiektJson.push(roboczy);
      
      tablica_dane[i].push(<button onClick={function(){RozrachSzczegoly(i,baza,WartoscWybranaW,WartoscWybranaL,WartoscWybranaWl,ObiektJson,Okno)}}>{ppp[i].nazwa_konta}</button>); 
    }
    i=0;
    
    while(i<ppp.length)
    {

      await dodaj(i);
      i++
    }
   
   

    UstDaneRozrachSzczegoly(ObiektJson);

    EkranRozliczen=<div>
      <TabelaDane Naglowek={Naglowek} Dane={tablica_dane} Liczba={i} SzerokoscNaglowka={SzerokoscNaglowka} WysokoscNaglowka={WysokoscNaglowka} WysokoscPozostalychWierszy={WysokoscPozostalychWierszy} Wyrownanie={Wyrownanie}/>;
      <button onClick={function(){ZakonczRozrach(Okno)}}>Zamknij</button>
    </div>

    Okno(<div>
      {EkranRozliczen}
    </div>)  
}

