

export default async function ListaWlasciciele(IdLokalu,IdWspolnoty){
  let zapytanie={
    klucz_sesji: sessionStorage.getItem("xpert"),
    id_lokalu: IdLokalu,id_wspolnoty: IdWspolnoty};
  let adres_serwera=""
  adres_serwera= window.sessionStorage.getItem("rodzaj_polaczenia");
  adres_serwera=adres_serwera+window.sessionStorage.getItem("adres");
  adres_serwera=adres_serwera+window.sessionStorage.getItem("port");
  const odpowiedz=await fetch(adres_serwera+'/lista_wlascicieli', {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },body: JSON.stringify(zapytanie)
  });
  const ppp=await odpowiedz.json();
  
  return(ppp);
}

