import React, { useState,useEffect } from 'react';
import './Pulpit.css';
import { useNavigate } from "react-router-dom";
import ListaZarzadcow from '../../komponenty/ListaZarzadcow/lista_zarzadcow';
import ListaWspolnot from '../../komponenty/ListaWspolnot/wspolnoty';
import ListaLokali from '../../komponenty/ListaLokali/lokale';
import ListaWlasciciele from '../../komponenty/ListaWlascicieli/wlasciciele';

import { NaliczeniaMc } from '../../komponenty/Naliczenia/Naliczenia';
import { RozrachunkiLista } from '../../komponenty/Rozrachunki/Rozrachunki';
import Start from '../Start/start'

import XpSelect from '../../komponenty/XpSelect/XpSelect';


async function Koniec(ZmienLogowanie)
{
    let zapytanie={
        klucz_sesji: sessionStorage.getItem("xpert"),
        };
    let adres_serwera;
    adres_serwera= window.sessionStorage.getItem("rodzaj_polaczenia");
    adres_serwera=adres_serwera+window.sessionStorage.getItem("adres");
    adres_serwera=adres_serwera+window.sessionStorage.getItem("port");
   
    const odpowiedz= await fetch(adres_serwera+'/wyloguj', {
        method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },body: JSON.stringify(zapytanie)
    });
        
           
    //const ppp=await odpowiedz.json();
    
   ZmienLogowanie(false);
    return;
}

export default function Pulpit()
{
    var SelectZ=null,SelectW=null,SelectL=null,SelectWl=null,Pulpit=null;
   //console.log("jest pulpit  "+window.sessionStorage.getItem("id_uzytkownika"));
   
    const [Logowanie,ZmienLogowanie]=useState(true);
    const [ListaZ,ZmienListaZ]=useState([]);
    const [OdswiezEkranZ,UstOdswiezEkranZ]=useState(false);
    const [WartoscWybranaZ,UstWartoscWybranaZ]=useState([]);
    
    const [ListaW,ZmienListaW]=useState([]);
    const [OdswiezEkranW,UstOdswiezEkranW]=useState(false);
    const [WartoscWybranaW,UstWartoscWybranaW]=useState([]);

    const [ListaL,ZmienListaL]=useState([]);
    const [OdswiezEkranL,UstOdswiezEkranL]=useState(false);
    const [WartoscWybranaL,UstWartoscWybranaL]=useState([]);

    const [ListaWl,ZmienListaWl]=useState([]);
    const [OdswiezEkranWl,UstOdswiezEkranWl]=useState(false);
    const [WartoscWybranaWl,UstWartoscWybranaWl]=useState([]);
    const [ZmienAkt,UstZmienAkt]=useState(false);
    const [ListaMiesiecy,UstListaMiesiecy]=useState([
    {"value":1,"label":"Styczeń"},
    {"value":2,"label":"Luty"},
    {"value":3,"label":"Marzec"},
    {"value":4,"label":"Kwiecień"},
    {"value":5,"label":"Maj"},
    {"value":6,"label":"Czerwiec"},
    {"value":7,"label":"Lipiec"},
    {"value":8,"label":"Sierpień"},
    {"value":9,"label":"Wrzesień"},
    {"value":10,"label":"Październik"},
    {"value":11,"label":"Listopad"},
    {"value":12,"label":"Grudzień"}]);
    const [MiesiacAktualny,UstMiesiacAktualny]=useState({"value":1,"label":"Styczeń"});

    const [OknoInformacji,UstOknoInformacji]=useState(null);
    const [DaneRozrachSzczegoly,UstDaneRozrachSzczegoly]=useState([{id_wlasciciela: 110,id_konta_ksiegowe:0,id_nag_rozrach:0,nazwa_konta:""}]);
    const navigate=useNavigate();
   let klucz=window.sessionStorage.getItem("id_uzytkownika");

    async function ZarzadcyOdswierz(value)
    {
        UstOknoInformacji(" ");
        let WynikWspolnoty,WynikLokale,WynikWlasciciele;
        
        UstWartoscWybranaZ(value);
        
        WynikWspolnoty=await ListaWspolnot(value.value);
        
        UstWartoscWybranaW(WynikWspolnoty[0]);
        ZmienListaW(WynikWspolnoty);   
        
        WynikLokale=await ListaLokali(WynikWspolnoty[0].value);
        
        ZmienListaL(WynikLokale);
        UstWartoscWybranaL(WynikLokale[0]);
        
        
        
        WynikWlasciciele=await ListaWlasciciele(WynikLokale[0].value,WynikWspolnoty[0].value);
       
        ZmienListaWl(WynikWlasciciele);
        UstWartoscWybranaWl(WynikWlasciciele[0]);
        

    }

    async function WspolnotyOdswierz(value)
    {
        let WybranyLokal,ListaWlascicieli;
        UstOknoInformacji(" ");
        UstWartoscWybranaW(value);
        WybranyLokal=await ListaLokali(value.value); 
        ZmienListaL(WybranyLokal);
        UstWartoscWybranaL(WybranyLokal[0]);
       
        ListaWlascicieli=await ListaWlasciciele(WybranyLokal[0].value,value.value);
        ZmienListaWl(ListaWlascicieli);
        UstWartoscWybranaWl(ListaWlascicieli[0]);

    }

    async function LokaleOdswierz(value)
    {
        
        let wynik;
        UstOknoInformacji(" ");
        UstWartoscWybranaL(value);
        wynik=await ListaWlasciciele(value.value,WartoscWybranaW.value);
        ZmienListaWl(wynik);
        UstWartoscWybranaWl(wynik[0]);
    }

    async function CzytaZ()
    {
        let id_wspolnoty,WynikZarzadcy=[],WynikWspolnoty=[],WynikLokale=[],WynikWlasciciele=[];
        
        WynikZarzadcy=await ListaZarzadcow()
        
        ZmienListaZ(WynikZarzadcy);
        UstOdswiezEkranZ(true);
        UstWartoscWybranaZ(WynikZarzadcy[0]);
        
        WynikWspolnoty=await ListaWspolnot(WynikZarzadcy[0].value);
        id_wspolnoty=WynikWspolnoty[0].value;
        
        ZmienListaW(WynikWspolnoty);    
        UstOdswiezEkranW(true);
        UstWartoscWybranaW(WynikWspolnoty[0]);
        
       
        WynikLokale=await ListaLokali(WynikWspolnoty[0].value);
        ZmienListaL(WynikLokale);
        UstOdswiezEkranL(true);
        UstWartoscWybranaL(WynikLokale[0]);
        
       
        WynikWlasciciele=await ListaWlasciciele(WynikLokale[0].value,id_wspolnoty);
        ZmienListaWl(WynikWlasciciele);
        UstOdswiezEkranWl(true);
        UstWartoscWybranaWl(WynikWlasciciele[0]);

    }
    
    
    useEffect(async function(){
         await CzytaZ();
        
    },[klucz]);
    
    
    if(window.sessionStorage.getItem("id_uzytkownika")==null)
    {
     Pulpit=<Start/>
         navigate("/");
         return(
             <div>
                 {Pulpit}
             </div>
             
         )
    }
    
    if(OdswiezEkranZ)
    {
        
        SelectZ=<XpSelect
        label="Wybierz zarządce" 
        name="nazwa"  
        className="class" 
        funkcja={ZarzadcyOdswierz}
        options={ListaZ}  
        WybranyElement={UstWartoscWybranaZ}/>
        
    }
    
    if(OdswiezEkranW)
    {
        SelectW=<XpSelect
        label="Wybierz wspólnote" 
        name="nazwa"  
        className="class" 
        options={ListaW}  
        funkcja={WspolnotyOdswierz}
        startElement={WartoscWybranaW}
        WybranyElement={UstWartoscWybranaW}/>
        
    }
    if(OdswiezEkranL)
    {
        
        SelectL=<XpSelect
        label="Wybierz Lokal" 
        name="nazwa"  
        className="class" 
        options={ListaL}  
        funkcja={LokaleOdswierz}
        startElement={WartoscWybranaL}
        WybranyElement={UstWartoscWybranaL}/>
    }
    if(OdswiezEkranWl)
    {
        SelectWl=<XpSelect
        label="Wybierz właściciela" 
        name="nazwa"  
        className="class" 
        options={ListaWl}  
        startElement={WartoscWybranaWl}
        WybranyElement={UstWartoscWybranaWl}/>
    }

    
    if(Logowanie)
    {
        
    if(ZmienAkt)
    {
        
        NaliczeniaMc(ListaL[0].nazwa_bazy,WartoscWybranaW,WartoscWybranaL,WartoscWybranaWl,ListaMiesiecy,MiesiacAktualny,UstMiesiacAktualny,ZmienAkt,UstZmienAkt,UstOknoInformacji);
        UstZmienAkt(false);
    }
    Pulpit=       
    <div className='StartPulpit'>
        <div className='PasekGornyPulpit'>

        </div>
        <div className='PasekWyboruPionPulpit'>
            <div className='wybor'>
                {SelectZ}
                
            </div>
            <div className='wybor'>
                {SelectW}
            </div>
            <div className='wybor'>
                {SelectL}
            </div>
            <div className='wybor'>
                {SelectWl}
            </div>
        

        </div>
        <div className='KartaDanychPulpit'>
            <div className='PasekPrzyciskowPulpit'>
                <button className='PrzyciskPulpit' onClick={function (){RozrachunkiLista(ListaL[0].nazwa_bazy,WartoscWybranaW,WartoscWybranaL,WartoscWybranaWl,DaneRozrachSzczegoly,UstDaneRozrachSzczegoly,UstOknoInformacji)}}>Rozrachunki</button>
                <button className='PrzyciskPulpit' onClick={function (){NaliczeniaMc(ListaL[0].nazwa_bazy,WartoscWybranaW,WartoscWybranaL,WartoscWybranaWl,ListaMiesiecy,MiesiacAktualny,UstMiesiacAktualny,ZmienAkt,UstZmienAkt,UstOknoInformacji)}}>Składnik opłat</button>
                <button className='PrzyciskPulpit' onClick={function (){Koniec(ZmienLogowanie)}}>Wyloguj</button>
            </div>
            {OknoInformacji}
        </div>
    </div>
    }
    else
    {
        Pulpit=<Start/>
        navigate("/");
        window.sessionStorage.clear();
    }    
    return(
        <div>
            {Pulpit}
        </div>
        
    )
}

