import './Naliczenia.css'

import TabelaDane from '../TabelaDane/TabelaDane';
import XpSelect from '../XpSelect/XpSelect';
//import Select from "react-select";

function FunMiesiac(UstZmienAkt)
{
  UstZmienAkt(true);
 
}


function ZakonczNaliczenia(Okno,UstMiesiacAktualny)
{
  UstMiesiacAktualny({"value":1,"label":"Styczeń"});
Okno("");

}
export async function NaliczeniaMc(baza,WartoscWybranaW,WartoscWybranaL,WartoscWybranaWl,ListaMiesiecy,MiesiacAktualny,UstMiesiacAktualny,ZmienAkt,UstZmienAkt,Okno)
{
  //let data=new Date();
  let zapytanie={
      klucz_sesji: sessionStorage.getItem("xpert"),
      id_wspolnoty: WartoscWybranaW.value,
      id_lokalu: WartoscWybranaL.value,
      id_wlasciciela: WartoscWybranaWl.value,
      miesiac: MiesiacAktualny.value,
      baza: baza};
  let tablica_dane=[],i=0,SzerokoscNaglowka=[4],WysokoscNaglowka,WysokoscPozostalychWierszy;
  let adres_serwera="",Naglowek=[4],WyrownanieKolumn=[4],suma=0;
  adres_serwera= window.sessionStorage.getItem("rodzaj_polaczenia");
  adres_serwera=adres_serwera+window.sessionStorage.getItem("adres");
  adres_serwera=adres_serwera+window.sessionStorage.getItem("port");
 
  
  WysokoscNaglowka=40;
  SzerokoscNaglowka[0] = "40%";
  SzerokoscNaglowka[1] = "20%";
  SzerokoscNaglowka[2] = "15%";
  SzerokoscNaglowka[3] = "20%";

  Naglowek[0]="Nazwa składnika ";
  Naglowek[1]="Data od";
  Naglowek[2]="Data do";
  Naglowek[3]="Wartość ";
  
  WyrownanieKolumn[0]=1;
  WyrownanieKolumn[1]=1;
  WyrownanieKolumn[2]=1;
  WyrownanieKolumn[3]=3;
  const odpowiedz= await fetch(adres_serwera+'/naliczenia', {
        method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },body: JSON.stringify(zapytanie)
    });
        
           
    const ppp=await odpowiedz.json();
    
    
    i=0;
    while (i<ppp.length)
    {
      tablica_dane.push(1);
      tablica_dane[i]=[];// Jest przykład w nauka_www
        
          //tablica_dane[i].push(<tr><td className='data_od'>{ppp[i].data_od}</td><td className='data_do'>{ppp[i].data_do}</td><td className='nazwa_skladnika'>{ppp[i].nazwa_skladnika}</td><td>{ppp[i].kwota} </td></tr>);
      tablica_dane[i].push(ppp[i].nazwa_skladnika);
         
      tablica_dane[i].push(ppp[i].data_od);
        
      tablica_dane[i].push(ppp[i].data_do);
         
      tablica_dane[i].push(ppp[i].kwota);
      suma=suma+ parseFloat(ppp[i].kwota,2);
      i++
    }
    suma=suma.toFixed(2)
    tablica_dane.push(1);
    tablica_dane[i]=[];// Jest przykład w nauka_www  
    tablica_dane[i].push("Razem opłaty ");
    tablica_dane[i].push("");
    tablica_dane[i].push("");
    tablica_dane[i].push(suma);
    
    Okno(<div>
      <div className='wybor_miesiaca'>
        <label className='label_wybor_miesiaca'>Wybrany miesiąc</label>
        <XpSelect className='select_wybor_miesiaca' funkcja={function(){FunMiesiac(UstZmienAkt)}}
        options={ListaMiesiecy}  
        WybranyElement={UstMiesiacAktualny}/>
        <button className='button_zamknij' onClick={function(){ZakonczNaliczenia(Okno,UstMiesiacAktualny)}}>Zamknij</button>
      </div>
      
      <TabelaDane Naglowek={Naglowek} Dane={tablica_dane} Liczba={i} SzerokoscNaglowka={SzerokoscNaglowka} WysokoscNaglowka={WysokoscNaglowka} WysokoscPozostalychWierszy={WysokoscPozostalychWierszy} Wyrownanie={WyrownanieKolumn}/>;
      
    </div>)    
     
}

