import "./TabelaDane.css";

//Style tabeli, gdzie ustalamy szerokosc i wysokość
function CalaTabelka(szerokosc,wyskosc) {
  
  return {
    /*border: "solid red",*/
    width: "70vw",
    height: "80vh",
    margin: "auto",
    float: "left",
    paddingLeft: "1vw"
    /*overflowX: "scroll",*/
    
   
  };
}
//Styl kazdego diva z petli naglowkowej, tutaj edytujemy kazdego diva z naglowka
        
function ustawStylNaglowka(NumerNaglowka, WysokoscNaglowka) {
  return {
    display: "grid",
    backgroundColor: "#CCFFCC",
    gridTemplateColumns: `${NumerNaglowka.join(" ")}`,
    gridTemplateRows: `${WysokoscNaglowka}`,
    textAlign: 'center',
    height: '30px'
   /* border: 'solid'*/

  };
}

//Ustawianie Parametrow naglowkow takich jak wyskosci i szerokosci, przekazywane jako props od App.js
function ustawStylElemntuPoziom(NumerNaglowka, WysokoscNaglowka) {
  return {
    display: "grid",
    paddingLeft: '1px',
    
    gridTemplateColumns: `${NumerNaglowka.join(" ")}`,
    gridTemplateRows: `${WysokoscNaglowka}`,
    height: '30px'
    
  };
}

function ustawStylElementuPion(rodzaj)
{
  let polozenie=null,PadLewy=null,PadPrawy=null;
  if(rodzaj===1){
    polozenie="left";
    PadLewy='5px';
    PadPrawy="0px";
  }
  if(rodzaj===2){
    polozenie="center";
    PadLewy='0px';
    PadPrawy="0px";
  }
  if(rodzaj===3){
    polozenie="right";
    PadLewy='0px';
    PadPrawy="5px";
  }
  return{
    textAlign: polozenie,
    paddingLeft: PadLewy,
    paddingRight: PadPrawy
  };
 
}


function ustawStylDanych()
{

  return{
    
  }
}

////////////////////////////////////////////////
export default function TabelaDane(Parametry) {
  let Tabela = [],
    NaglowekTabeli = [],
    DaneTabeli = [],
    WierszTabeli = [],
    i = 0,
    i_kol = 0,
    i_wiersz = 0,
    StylCalaTabelka = null,
    StyleNaglowkow = null,
    StylElem = null,
    StylNag = null,
    StylElemPion=null,
    StylDanych=null,
    Wyrownanie=[],
    szerokosc=0,
    wyskosc=0;

  StylDanych=ustawStylDanych()
  StylCalaTabelka = CalaTabelka(szerokosc,wyskosc);
  StylElemPion=ustawStylElementuPion();
  StyleNaglowkow = ustawStylNaglowka(Parametry.SzerokoscNaglowka,Parametry.WysokoscNaglowka);
  
Wyrownanie=Parametry.Wyrownanie;
  //Petla ktora tworzy naglowek tabeli, dodaje UstawStylNaglowka i tworzy diva

  while (i < Parametry.Naglowek.length) {
    NaglowekTabeli.push(
      <div key={i} style={StyleNaglowkow} >
        {Parametry.Naglowek[i]}
      </div>
    );
    i++;
  }

  i_wiersz = 0;
  
  while (i_wiersz < Parametry.Dane.length)
   {
    i_kol = 0;
    WierszTabeli = [];
    if (Array.isArray(Parametry.Dane[i_wiersz])) {
      while (i_kol < Parametry.Dane[i_wiersz].length) {
        WierszTabeli.push(
          <div key={i_kol} style={ustawStylElementuPion(Wyrownanie[i_kol])}>
            {Parametry.Dane[i_wiersz][i_kol]}
          </div>
        );
        i_kol++;
      }
    }

    StylElem = ustawStylElemntuPoziom(Parametry.SzerokoscNaglowka,Parametry.WysokoscPozostalychWierszy);
    DaneTabeli.push(
      <div
        className={i_wiersz % 2 === 0 ? "Szarosc" : ""}
        style={StylElem}
        key={i_wiersz}
      >
        {WierszTabeli}
      </div>
    );
    i_wiersz++;
  }
  /*
  
      <div style={StylDanych}>
        {DaneTabeli}
      </div>
*/
  Tabela = (
    <div style={StylCalaTabelka}>
      <div style={StyleNaglowkow}>
        {NaglowekTabeli}
      </div>
      <div style={StylDanych}>
        {DaneTabeli}
      </div>
    </div>
  );
/* <div></div>*/
  return Tabela;
}
