import {Route,Routes } from "react-router-dom";

import Start from "../src/strony/Start/start";
import Pulpit from "./strony/Pulpit/Pulpit";


export default function App() {
  

  return (
    <div >
      <Routes>
        <Route exact path="/" element =<Start/> />
        <Route exact path="/pulpit" element=<Pulpit/> />
      </Routes>    
    </div>
  );
}

/* */

 
