import React from "react";
import './KlawiszButton.css';


export default function Przycisk(napis,funkcja)
{
    let przycisk=null;

    przycisk=<button className="KlawiszLogowanie" onClick={funkcja}>{napis}</button>
    
    return(przycisk);
    
}