import { MD5 } from "md5-js-tools";
import "./Logowanie.css"



export function LogowanieAnuluj(ZmienZalogowany)
{
    
    ZmienZalogowany(null);

}

function SprawdzLogin(ZmienDaneForm)
{
    
        
        const uzytkownik=document.getElementById("login").value;
        const haslo=document.getElementById("haslo").value;
        const token=MD5.generate(uzytkownik+JSON.parse(Math.floor((Date.now()) / 1000)));
        
        sessionStorage.setItem('xpert', token);
        ZmienDaneForm({nazwa: uzytkownik,haslo: haslo,klucz_sesji: token});
    
    
    
}


export default function Logowanie(ZmienZalogowany,ZmienDaneForm){
    const OknoLogowanie=<div className="okno">
    <h3>Logowanie</h3>
    <div className="formularzLogowania">
        <div>
            <label className="formularzLabel">Użytkownik</label>
            <input className="formularzInput"  type="text" name="login" id="login"/>
        </div>
        <div>
            <label className="formularzLabel">Hasło</label>
            <input className="formularzInput" type="password" name="haslo" id="haslo"/>
        </div>
        <div>
            
            <button onClick={function(){SprawdzLogin(ZmienDaneForm)}} className="formularzButton">Zaloguj</button>
            
            <button onClick={function(){LogowanieAnuluj(ZmienZalogowany)}} className="formularzButton">Anuluj</button>
        </div>
    </div>
</div>
ZmienZalogowany(OknoLogowanie);
    
}