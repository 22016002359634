import React, {useEffect,useState}from "react";
import Select from "react-select";

export default function XpSelect(props) {
  const [selectedOption, setSelectedOption] = useState([]);
  useEffect(function(){
    if(props.startElement)
    {
      setSelectedOption(props.startElement);
    }
    else
    {
      setSelectedOption(props.options[0]);
    }
    
  },[props.startElement]);
    
    
    
    function dupa(value){
    
        setSelectedOption(value);
        props.WybranyElement(value);
        if(props.funkcja)
        {
          props.funkcja(value);
        }
    
    }
    
    
    return (
      <div className="App">
        <Select
          label = {props.label}
          name={props.nazwa}
          className={props.className}
          placeholder={selectedOption}
          searchable={false}
          value={selectedOption}
          onChange={dupa}
          options={props.options}
        />
      </div>
    );
  }